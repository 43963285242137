export const formatPrice = (price, separator, decimals = 1) => {
  let priceValue;
  priceValue = price > 0 && Number(parseFloat(price)).toFixed(decimals);

  if (isNaN(priceValue)) {
    return '--';
  }

  if (separator && priceValue) {
    priceValue = priceValue.toString().replace('.', ',');
  }

  return priceValue;
};
