import axios from 'axios';
import { sections } from './gstConfig';

const getProd = (cat) =>
  axios(
    `https://docs.google.com/spreadsheets/d/1KzNaZSxnVCg0mbU30L9d2YDJ3fJsO531MH5_wIKMeIU/gviz/tq?tqx=out:json&sheet=${cat}`
  );

const FormatResponse = (response) =>
  new Promise((resolve, rej) => {
    const json = JSON.parse(response.substring(47).slice(0, -2));
    const rows = json.table.rows;
    const cols = json.table.cols;

    const data = rows.reduce((group, item) => {
      const properties = item.c.reduce((prop, item, index) => {
        const label =
          cols[index].label.length > 0 ? cols[index].label : cols[index].id;

        if (item) {
          if (item.v) {
            prop[label] = typeof item.v === 'number' ? item.f : item.v || '';
          }
        }

        return prop;
      }, {});

      group.push(properties);

      return group;
    }, []);

    resolve(data);
  });

const groupData = (data, sections) =>
  new Promise((res, rej) => {
    const dataObject = data.map((item, index) => {
      return {
        title: sections[index],
        items: item,
      };
    });

    res(dataObject);
  });

export const getAllData = () =>
  new Promise((resolve) => {
    const apiActions = sections.map((item) => getProd(item));

    Promise.all(apiActions).then((result) => {
      const resultActions = result.map((item) => FormatResponse(item.data));

      Promise.all(resultActions).then((dataGroup) => {
        groupData(dataGroup, sections).then((shapedData) => {
          resolve(shapedData);
        });
      });
    });
  });
