import { getAllData } from './getSheetData';
import { formatPrice } from './helpers';

export const generateMenu = () => {
  const menu = document.querySelector('.menu');

  const generateItems = (array) => {
    let str = ``;

    array.forEach((item) => {
      const { name, description, price, details } = item;
      const priceValue = formatPrice(price, true, 2);

      str += `
          <li class="menu__item">
            <div class="menu__item-wrapper">
              <p class="menu__item-name">${name || ''}</p>
              ${
                details
                  ? `<p class="menu__list-menu-details">${details}</p>`
                  : ''
              }
              <p class="menu__item-description">${description || ''}</p>
            </div>
            <p class="menu__item-price"><span>${
              Boolean(priceValue) ? priceValue : ''
            }</span>${Boolean(priceValue) ? '€' : ''}</p>
          </li>
          `;
    });
    return str;
  };

  getAllData().then((res) => {
    res.forEach((element) => {
      const { title, items } = element;
      const hasExtraInfos = items[0].hasOwnProperty('allergeni');
      const subtitle = hasExtraInfos ? items[0].allergeni : null;
      const imageDivider = items[0].hasOwnProperty('image');
      const image = imageDivider ? items[0].image : null;

      menu.innerHTML += `
      ${
        image
          ? `        <div class="menu__img-container">
          <img class="menu__img" src="src/assets/${image}.jpg" alt="" />
        </div>
`
          : ''
      }
        <ul class="menu__list">
        <h2 class="menu__item-title">${title}</h2>
        ${generateItems(items)}
        ${
          subtitle
            ? `<p class="menu__list-subtitle">* Allergeni: ${subtitle}</p>`
            : ''
        }
        </ul>
        `;
    });
  });
};
