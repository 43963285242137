export const sections = [
  'Bibite/birre',
  'Bubble waffles *',
  'Cornetti *',
  'Crepes dolci *',
  'Menù salato',
  'Pancake *',
  'Waffles *',
  'Yogurt',
];
