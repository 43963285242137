import { generateMenu } from './generateMenu';
import '../stylesheets/style.scss';
import barba from '@barba/core';
import gsap from 'gsap';
import { getAllData } from './getSheetData';

const pageName = document.querySelector('body').dataset.barbaNamespace;
let isLoaded = false;

barba.init({
  timeout: 5000,
  transitions: [
    {
      name: 'opacity-transition',

      enter() {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
        generateMenu();
        isLoaded = true;
      },

      after(data) {
        return gsap.from(data.next.container, {
          opacity: 0,
        });
      },
    },
  ],
});

if (!isLoaded && pageName === 'menu') {
  document.body.scrollTop = document.documentElement.scrollTop = 0;
  generateMenu();
}
